import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import GoogleSignIn from 'components/googleSignIn'
import SmsDashboard from 'containers/smsDashboard'

import './dashboard.sass'

class DashboardPage extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
  }

  render() {
    const {isLoggedIn} = this.props

    return (
      <div className="dashboard-page">
        <h2 className="dashboard-page-title">
          Sms2Firebase
        </h2>
        <div>
          {isLoggedIn ? <SmsDashboard /> : <GoogleSignIn />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn
})

export default connect(
  mapStateToProps
)(DashboardPage)
