import './init'
import React from 'react'
import ReactDom from 'react-dom'
import Root from './root'
import firebaseApp from 'firebaseApp'
import {initializeStore} from './redux/store'
import {userLoggedIn, userSignOut} from 'reducers/user'

// render app
const renderApp = (Component, appRoot, store) => {
  // firebase handler
  firebaseApp.auth().onAuthStateChanged((user) => {
    if (user) {
      store.dispatch(userLoggedIn(user.email))
    } else {
      store.dispatch(userSignOut())
    }
  });

  ReactDom.render(
    <Component store={store} />,
    appRoot, () => {
      // need to make this for feature tests - application ready for testing
      window.__isAppReady = true
    })
}
// firebase preload data
const firebaseUser = firebaseApp.auth().currentUser

const prepareStoreData = () => ({
  user: {
    email: (firebaseUser ? firebaseUser.email : null),
    isLoggedIn: !!firebaseUser
  }
})

// init store and start app
const appRoot = document.getElementById('app-root')
const store = initializeStore(prepareStoreData())

renderApp(Root, appRoot, store)
