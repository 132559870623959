import React from 'react'
import PropType from 'prop-types'
import CopyButton from 'components/copyButton'

import './sms-item.sass'

export default class SmsItem extends React.Component {
  static propTypes = {
    item: PropType.shape({
      id: PropType.string.isRequired,
      from: PropType.string.isRequired,
      text: PropType.string.isRequired,
      sentAt: PropType.number.isRequired
    }).isRequired
  }

  render() {
    const {id, from, sentAt, text} = this.props.item
    const humanizedDate = new Date(sentAt * 1000).toISOString()

    return (
      <li key={id} className='sms-item'>
        <div className='sms-item-header'>
          <div className='sms-item-from'>
            From: {from}
          </div>
          <div className='sms-item-sent-at'>
            {humanizedDate}
          </div>
        </div>
        <div className='sms-item-body'>
          <div className='sms-item-content'>
            {text}
          </div>
          <div className='sms-item-copy-button'>
            <CopyButton className="sms-item-copy-button-self" {...this.getCopyAttr(text)} />
          </div>
        </div>
      </li>
    )
  }

  getCopyAttr(text) {
    const pinMatch = text.match(/PIN is ([\d]{5})/)
    if (pinMatch) {
      return {
        text: pinMatch[1],
        label: 'Copy PIN'
      }
    }
    return {
      text: text,
      label: 'Copy'
    }
  }

}
