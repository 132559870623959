import React from 'react'
import firebaseApp from 'firebaseApp'

import './google-sign-in.sass'

export default class GoogleSignIn extends React.Component {
  loginByGoogle(e) {
    e.preventDefault()

    const provider = new firebaseApp.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
    provider.setCustomParameters({
      hd: 'railsware.com'
    })
    firebaseApp.auth().languageCode = 'en'
    firebaseApp.auth().signInWithPopup(provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const token = result.credential.accessToken
      // The signed-in user info.
      // const user = result.user
      // ...
    }).catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code
      // const errorMessage = error.message
      // The email of the user's account used.
      // const email = error.email
      // The firebase.auth.AuthCredential type that was used.
      // const credential = error.credential
    })
  }

  render() {
    return (
      <div className="google-sign-in-block">
        <button className="google-sign-in-button" onClick={this.loginByGoogle.bind(this)}>
          Login by Google
        </button>
      </div>
    )
  }
}
