import React from 'react'

import './app.sass'

export default class AppLayout extends React.Component {
  render() {
    const {children} = this.props

    return (
      <div className="app">
        <main className="app__main" role="main">
          {children}
        </main>
      </div>
    )
  }
}
