import React from 'react'
import PropType from 'prop-types'
import firebaseApp from 'firebaseApp'
import Logger from 'lib/logger'

import './sms-dashboard.sass'
import SmsItem from "../smsItem";

export default class SmsDashboard extends React.Component {
  static propTypes = {
    list: PropType.arrayOf(
      PropType.shape({
        id: PropType.string.isRequired,
        from: PropType.string.isRequired,
        text: PropType.string.isRequired,
        sentAt: PropType.number.isRequired
      }).isRequired
    ),
    actions: PropType.shape({
      addSmsToList: PropType.func.isRequired,
      updateSmsInList: PropType.func.isRequired,
      removeSmsFromList: PropType.func.isRequired
    }).isRequired
  }

  constructor(props) {
    super(props)
    this.db = firebaseApp.firestore()
    this.collection = this.db.collection("sms")
  }

  logoutUser(e) {
    e.preventDefault()
    if (confirm('Do you really want to logout?')) {
      firebaseApp.auth().signOut()
    }
  }

  componentDidMount() {
    this.querySelector = this.collection.orderBy("sentAt", "desc").limit(100)
    this.dbListener = this.querySelector.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().map((change) => {
        const {sentAt, ...rest} = change.doc.data()

        const document = {
          ...rest,
          sentAt: sentAt.seconds,
          id: change.doc.id
        }

        switch(change.type) {
          case 'added': {
            this.props.actions.addSmsToList(document)
            break;
          }
          case 'modified': {
            this.props.actions.updateSmsInList(document)
            break;
          }
          case 'removed': {
            this.props.actions.removeSmsFromList(document)
            break;
          }
          default: {
            Logger.warn('Not handled case', change)
          }
        }
      })
    })
  }

  componentWillUnmount() {
    if (this.dbListener) {
      this.dbListener()
    }
  }

  onLoadMore(e) {
    e.preventDefault()
    this.paginatedQuerySelector = this.paginatedQuerySelector || this.querySelector
    this.paginatedQuerySelector.get()
      .then((snapshot) => {
        const last = snapshot.docs[snapshot.docs.length - 1]
        this.paginatedQuerySelector = this.collection.orderBy("sentAt", "desc")
          .startAfter(last.data().sentAt)
          .limit(100)
        return this.paginatedQuerySelector.get()
      }).then((records) => {
        records.docs.forEach((record) => {
          const {sentAt, ...rest} = record.data()
          const document = {
            ...rest,
            sentAt: sentAt.seconds,
            id: record.id
          }
          this.props.actions.addSmsToList(document)
        })
      })
  }

  render() {
    const {list} = this.props

    return (
      <div className="sms-dashboard-block">
        <a href="#" onClick={this.logoutUser.bind(this)} className='sms-dashboard-logout-link'>
          Logout
        </a>
        <ul className='sms-dashboard-list'>
          {list.map((item) => {
            return (
              <SmsItem key={item.id} item={item}/>
            )
          })}
        </ul>
        <div className="sms-dashboard-load-block">
          <button className='sms-dashboard-load-more' onClick={this.onLoadMore.bind(this)}>
            Load more
          </button>
        </div>
      </div>
    )
  }
}
