import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import AppLayout from './pages/app'
import Dashboard from './pages/dashboard'

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  render() {
    const {store} = this.props

    return (
      <Provider store={store} key="provider">
        <AppLayout>
          <Dashboard />
        </AppLayout>
      </Provider>
    )
  }
}
