import {combineReducers} from 'redux'
import {createAction, createReducer} from 'redux-act'

export const userLoggedIn = createAction('User is logged in')
export const userSignOut = createAction('User is sign out')

const isLoggedIn = createReducer({
  [userLoggedIn]: () => true,
  [userSignOut]: () => false
}, false)

const email = createReducer({
  [userLoggedIn]: (state, payload) => payload,
  [userSignOut]: () => null
}, null)

export const reducer = combineReducers({
  isLoggedIn,
  email
})
