export default {
  default: {
    apiKey: 'AIzaSyBgo00o1WIY893zAtey5-KOdUPMTa7UM2M',
    authDomain: 'sms2firebase-railsware.firebaseapp.com',
    databaseURL: 'https://sms2firebase-railsware.firebaseio.com',
    projectId: 'sms2firebase-railsware',
    storageBucket: 'sms2firebase-railsware.appspot.com',
    messagingSenderId: '828160075041'
  }
}
