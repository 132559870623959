import {combineReducers} from 'redux'
import {createAction, createReducer} from 'redux-act'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'

export const addSmsToList = createAction('Add sms to list')
export const updateSmsInList = createAction('Update SMS in list')
export const removeSmsFromList = createAction('Remove SMS from list')

const addInList = (list, document) => {
  return _uniqBy(list.concat([document]), (doc) => doc.id)
}

const updateInList = (list, document) => {
  return list.reduce((arr, item) => {
    if (item.id === document.id) {
      return arr.concat([document])
    }
    return arr.concat([item])
  }, [])
}

const removeInList = (list, document) => {
  return list.reduce((arr, item) => {
    if (item.id === document.id) {
      return arr
    }
    return arr.concat([item])
  }, [])
}

const sortSms = (list) => _sortBy(list, (doc) => -doc.sentAt)

const list = createReducer({
  [addSmsToList]: (state, payload) => sortSms(addInList(state, payload)),
  [updateSmsInList]: (state, payload) => sortSms(updateInList(state, payload)),
  [removeSmsFromList]: (state, payload) => sortSms(removeInList(state, payload))
}, [])

export const reducer = combineReducers({
  list
})
