import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import SmsDashboard from 'components/smsDashboard'
import {
  addSmsToList,
  updateSmsInList,
  removeSmsFromList
} from 'reducers/sms'

const mapStateToProps = (state) => ({
  list: state.sms.list
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    addSmsToList: addSmsToList,
    updateSmsInList: updateSmsInList,
    removeSmsFromList: removeSmsFromList
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsDashboard)
